import React from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Breadcrumb, Select, Popconfirm, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getPackages, updateAuth } from "../../../actions/package";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import ModalForm from './modalForm';
import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const PackageList = ({ getPackages, updateAuth }) => {
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState([]);


  const getData = async () => {
    const res = await getPackages()
    setData(res);
  }

  useEffect(() => {
    getData()
  }, [])


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };
  
  const handleStatus = async (user,active) => {
    const code = await updateAuth(user,active);
    if(code === 201 ){
      getData();
    }
  }
  const handleEdit = async (item) => {
    setIsEditModalOpen(true);
    setEditData(item)
    // const code = await deleteUser(user);
    // if(code === 201 ){
    //   getData();
    // }
  }

  const columns = [
    {
      title: 'SL',
      dataIndex: 'sl',
      key: 'sl',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: '20%',
      ...getColumnSearchProps('value'),
    },
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      width: '20%',
      ...getColumnSearchProps('money'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'Create at',
      dataIndex: 'create_at',
      key: 'create_at',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const createData = (sl ,name, value, money, status, create_at, action) => {
    return {sl ,name, value, money, status, create_at, action};
  };

  const rows = [
    data
      ? data?.map((item, key) => createData(
        key + 1, 
        item?.name, 
        item?.value, 
        item?.money, 
        item.status ?
          <> 
            <Tag color="#87d068">Active</Tag> 
            {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => handleStatus(item.app_id, false)}><Button size="small" type="danger" icon={<StopOutlined  />}>Disable</Button></Popconfirm> */}
          </>: 
          <>
          <Tag color="#f50">Inactive</Tag>
          {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => handleStatus(item.app_id, true)}><Button size="small" type="primary" icon={<CheckCircleOutlined  />}>Activate</Button></Popconfirm> */}
          </>,
        item?.create_at,
        <>
          <Button type='primary' size="small" danger icon={<EditOutlined  />}  onClick={() => handleEdit(item)}>Edit</Button>
        </>
        ))
      : "",
      
  ];

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Packages</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Packages"
        subTitle="TV App User Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { () => setIsCreateModalOpen(true) }>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <br />  
      <br />  
      <Table columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <br />
      
     {isCreateModalOpen ? 
      <ModalForm 
        visible={isCreateModalOpen} 
        setVisible={() => setIsCreateModalOpen(false)}
        refresh={getData}
      />
     :null}
     {isEditModalOpen ? 
      <EditModalForm 
        visible={isEditModalOpen} 
        setVisible={() => setIsEditModalOpen(false)}
        refresh={getData}
        data={editData}
      />
     :null}
    </Content>
   );
}
 
export default connect(null, {getPackages, updateAuth}) (PackageList);