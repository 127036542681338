import history from "../history";
import api from "./apis";

import {
  ERROR_MESSAGE,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR
} from "./types";

// ==========================User==============
export const createPackage = (data) => async (dispatch) => {
  
  dispatch({type: LOADEDING});

  const object = {
    name: data?.name,
    value: data?.value,
    money: data?.money,
    status: data?.status,
  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/user-management/package/create",
      { ...object }
    );

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        //err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
       // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getPackages = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/user-management/package/all"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updatePackage = (data, id) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  const object = {
    uuid: id,
    name: data?.name,
    money: data?.money,
    value: data?.value,
    status: data?.status,
  };

  try {
    const response = await api(localStorage.getItem("token")).put(
      "/user-management/package/update",
      { ...object }
    );

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});

      });
        //err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
       // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateAuth = (user_id, status) => async (dispatch) => {
  dispatch({type: LOADEDING});
  
  const object = {
    status,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).put(`/end-user-management/${user_id}`,{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "User authentication is updated succesfully"});
    
    return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const userHelper = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/end-user-management/user/helper"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const packageHelper = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/end-user-management/package/helper"
    );
    console.log(response.data)
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};
