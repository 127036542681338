import history from "../history";
import api from "./apis";

import {
  ERROR_MESSAGE,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "./types";

// ==========================Banner==============
export const createBanner = (abc, img) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  const data = new FormData();
  data.append("files[]", img);

  try {
    const response = await api().post(
      "/banner-management/banner/upload",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    // axios({
    //   method: "POST",
    //   url: 'https://api.demo.hidayahsmart.solutions/v3/banner-management/banner/upload',
    //   data: data,
    //   headers: {
    //     // 'Content-Type': 'multipart/form-data',
    //     'Authorization': `Bearer ${localStorage.getItem("token")}`
    //   }
    // })
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        console.log(key, index);
      });
      //err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getBanner = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/banner-management/banner/all"
    );

    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getBannerById = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/banner-management/banner/${id}`
    );

    dispatch({ type: LOADED });
    return response?.data;
    // dispatch({ type: GET_USERS, payload: response.data.users });
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const updateBanner = (data, id) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  const object = {
    id,
    status: data?.status.toString(),
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).put(
      "/banner-management/banner/status",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Banner is updated succesfully",
      });

      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const deleteBanner = (user, remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const response = await api(localStorage.getItem("token")).delete(
      `/banner-management/banner/delete/${user}`
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Banner is deleted succesfully",
      });

      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
