import React, { Component } from "react";
//import 'antd/dist/antd.css';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteUser,
  getUsers,
  updateAuth,
  updateUserPassword,
} from "../../../actions/user";
import Remarks from "../../../component/remark";
import ChangePass from "./changePass";
import EditModalForm from "./editModalForm";
import ModalForm from "./modalForm";

class UserList extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    data: [],
    visible: false,
    passVisible: false,
    modalVisible: false,
    editModalVisible: false,
    placement: "bottom",
    id: "",
  };
  componentDidMount = async () => {
    const res = await this.props.getUsers();
    this.setState({ data: res });
  };
  handleStatus = async (user, active) => {
    const code = await this.props.updateAuth(user, active);
    if (code === 201) {
      const res = await this.props.getUsers();
      this.setState({ data: res });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  createData = (
    user_id,
    name,
    company,
    branch,
    dept,
    authenticated,
    role,
    action
  ) => {
    return {
      user_id,
      name,
      company,
      branch,
      dept,
      authenticated,
      role,
      action,
    };
  };

  showDrawer = (id) => {
    this.setState({
      visible: true,
      id: id,
    });
  };

  handleRefresh = async () => {
    const res = await this.props.getUsers();
    this.setState({ data: res });
  };

  handlePassVisible = (id) => {
    this.setState({ passVisible: true, id: id });
  };

  handleModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  handleEditModalVisible = (user_id) => {
    this.setState({
      editModalVisible: !this.state.editModalVisible,
      id: user_id,
    });
  };

  onClosePass = () => {
    this.setState({
      passVisible: false,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const columns = [
      {
        title: "User ID",
        dataIndex: "user_id",
        key: "user_id",
        width: "10%",
        ...this.getColumnSearchProps("user_id"),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "20%",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
        width: "20%",
        ...this.getColumnSearchProps("company"),
      },
      {
        title: "Branch",
        dataIndex: "branch",
        key: "branch",
        width: "20%",
        ...this.getColumnSearchProps("branch"),
      },
      {
        title: "Department",
        dataIndex: "dept",
        key: "dept",
        width: "20%",
        ...this.getColumnSearchProps("dept"),
      },
      {
        title: "Status",
        dataIndex: "authenticated",
        key: "authenticated",
        width: "15%",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        width: "20%",
        ...this.getColumnSearchProps("role"),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "20%",
      },
    ];
    const { data, placement, visible, id, passVisible } = this.state;
    const rows = [
      data
        ? data.map((user) =>
            this.createData(
              user.user_id,
              user.full_name,
              user.company,
              user?.branch,
              user?.department,
              <>
                {user.status ? (
                  <>
                    <Tag color="#87d068">Active</Tag>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => this.handleStatus(user.user_id, false)}
                    >
                      <Button
                        size="small"
                        type="danger"
                        icon={<StopOutlined />}
                      >
                        Disable
                      </Button>
                    </Popconfirm>
                  </>
                ) : (
                  <>
                    <Tag color="#f50">Inactive</Tag>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => this.handleStatus(user.user_id, true)}
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<CheckCircleOutlined />}
                      >
                        Activate
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </>,

              user?.role,
              <>
                <Button
                  size="small"
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => this.handleEditModalVisible(user.uuid)}
                >
                  Edit
                </Button>{" "}
                &nbsp;
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.showDrawer(user.user_id)}
                >
                  <Button size="small" type="danger" icon={<DeleteOutlined />}>
                    Delete
                  </Button>
                </Popconfirm>
                <br />
                <br />
                <Button
                  size="small"
                  type="primary"
                  icon={<SettingOutlined />}
                  onClick={() => this.handlePassVisible(user.user_id)}
                >
                  Change Password
                </Button>{" "}
                &nbsp;
              </>
            )
          )
        : "",
    ];
    return (
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ padding: "10px 0px" }}>
          <Breadcrumb.Item>
            <Link to="/dashboard">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>User List</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="User List"
          subTitle="User Management"
          extra={[
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={this.handleModalVisible}
            >
              Create New
            </Button>,
          ]}
        ></PageHeader>

        <Table
          columns={columns}
          dataSource={rows[0]}
          scroll={{ x: 1000 }}
          sticky
        />
        <Remarks
          placement={placement}
          visible={visible}
          fnc={this.onClose}
          id={id}
          delFnc={this.props.deleteUser}
          data={this.state.data}
          refresh={this.handleRefresh}
        />
        <ChangePass
          placement={placement}
          visible={passVisible}
          fnc={this.onClosePass}
          id={id}
          passFnc={this.props.updateUserPassword}
          data={this.state.data}
          refresh={this.handleRefresh}
        />

        {this.state.editModalVisible ? (
          <EditModalForm
            visible={this.state.editModalVisible}
            setVisible={this.handleEditModalVisible}
            user_id={this.state.id}
            refresh={this.handleRefresh}
          />
        ) : null}
        <ModalForm
          visible={this.state.modalVisible}
          setVisible={this.handleModalVisible}
          refresh={this.handleRefresh}
        />
      </Content>
    );
  }
}

export default connect(null, {
  getUsers,
  updateAuth,
  deleteUser,
  updateUserPassword,
})(UserList);
