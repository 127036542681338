import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb,Modal, Divider } from 'antd';
import CommonForm, { renderDate, renderInput, renderSelect, renderTextField } from '../../../component/form/form';

import { connect } from 'react-redux';

import { updateBranch, getBranchById, getBranch } from "../../../actions/branch";
import { getCompany } from "../../../actions/compnay";

import { useEffect, useState} from 'react';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const EditModalForm = ({visible, setVisible,uuid,getBranch ,getBranchById, getCompany, updateBranch,refresh}) => {
      const [data, setData] = useState(null)
      const [roles, setRoles] = useState(null)
      const [company, setCompany] = useState(null)
      const [form] = Form.useForm();
      const createOptions = (label,value) => {
        return {label,value}
      }
      useEffect(async() => {
        const comp = await getCompany();
        let compOption = [];
        compOption = (
          comp ? comp.map(item => 
            createOptions(item?.name , item?.uuid))
        : null)
        {console.log(compOption)}
        setCompany(compOption)

        const res = await getBranch();
        setRoles(res)
      
        const result = await getBranchById(uuid);
        setData(result[0])
              form.setFieldsValue({
                name: result[0]?.name,
                company: result[0]?.company,
                active: result[0]?.active,
              });
      }, [])
      const createRoles = (label,value) => {
        return {label,value}
      }
      const onFinish = async (values) => {
        // console.log('Success:', values);
        const code = await updateBranch(uuid,values)
        if(code === 201){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      let option = [];
      option = (
        roles ? roles.map(item => 
          createRoles(item.name , item.name ))
      : null)

    return ( 
          
          <Modal
          title="Edit Branch"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          {data ?
              <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{alignSelf: 'center'}}
              requiredMark={false}
              form={form}
              >
                {/* <Form.Item
                  label="UUID"
                  name="uuid"
            
                >
                  <Input name="uuid" disabled/>
               </Form.Item> */}
                {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
                {renderInput('name', 'Branch Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderSelect('company', 'Company', [
                    { required: true},
                  ],
                  'Select a option', company, 'none')
                }
               {renderSelect('active', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                
                
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              
              </Form>
          :null}
        </Modal>
     );
}
 
export default connect(null,{getBranch,updateBranch, getBranchById, getCompany})(EditModalForm);
