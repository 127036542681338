import React, { useEffect, useState } from 'react'
import { Form, Button, Select ,Modal } from 'antd';
import { renderInput, renderSelect } from '../../../component/form/form';
import { userHelper, packageHelper } from "../../../actions/package";
import { connect } from 'react-redux';
import { createUser,getCompanyhelper,getBranchelper } from "../../../actions/appUser";
import SelectBox from '../../../component/form/select';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const ModalForm = ({visible, setVisible, getBranchelper, createUser, refresh, userHelper, packageHelper,getCompanyhelper }) => {
    
  const [packageData, setPackageData] = useState([]);
  const [company, setCompany] = useState(null)
  const [branch, setBranch] = useState(null)
  const [user, setUser] = useState([]);
  const [form] = Form.useForm();
  const createOptions = (label,value) => {
    return {label,value}
  }
  // console.log(packageData)
  const getData = async () => {
    const usr = await userHelper();
    setUser(usr);
    const comp = await getCompanyhelper();
    let compOption = [];
    compOption = (
      comp ? comp.map(item => 
        createOptions(item?.name , item?.uuid))
    : null)
    setCompany(compOption)

    const pkg = await packageHelper();
    setPackageData(pkg);
  }

  let brnOption = [];
  const branchApi = async(id) => {
    const brn = await getBranchelper(id);
    brnOption = (
      brn ? brn.map(item => 
        createOptions(item?.name , item?.uuid))
    : null)
    setBranch(brnOption)
  }

  const createoption = (value, label) => {
    return {value, label};
  };

  const packageOption = [...
    packageData?.map(item => createoption(
      item.uuid,
      item.name,
    ))
  ]

  const userOption = [
    user?.map(item => createoption(
      (item.user_id).toString(),
      item.name,
    ))
  ]
  useEffect(() => {
    getData()
  }, [])

      const onFinish =async (values) => {
        // console.log('Success:', values);
        const code = await createUser(values)
        if(code === 201){
          refresh()
          setVisible()
          form.resetFields();
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return ( 
          <Modal
          title="Create App User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
            
                {renderInput('app_id', 'App ID', [
                    { required: true, message: 'This field is required!' },
                    { pattern: RegExp('^[0-9]+$'), message: 'Only number acceptable' },
                  ])
                }
                {renderInput('name', 'Location', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                
                {company ? 
                  <SelectBox
                    name="company"
                    label="Company"
                    rules={[{ required: true, message: "payment type is required" }]}
                    options={company}
                    placeholder="Select a option"
                    onChange={(e) => {
                      branchApi(e);
                    }}
                  />
                : null}
                {branch ? renderSelect('branch', 'Branch', [
                    { required: true},
                  ],
                  'Select a option', branch, 'none')
                : null}
                {console.log(packageOption)}
                {packageOption? renderSelect('package', 'Package', [
                    { required: true},
                  ],
                  'Select a option', packageOption, 'none')
                : null}
                {renderSelect('status', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              
              </Form>

        </Modal>
     );
}
 
export default connect(null,{createUser, userHelper,getCompanyhelper,getBranchelper, packageHelper})(ModalForm);
