import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb,Modal, Divider } from 'antd';
import CommonForm, { renderDate, renderInput, renderSelect, renderTextField } from '../../../component/form/form';

import { connect } from 'react-redux';
import { createBranch, getBranch } from "../../../actions/branch";
import { getCompanyHelper } from "../../../actions/compnay";
import { useEffect, useState} from 'react';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const ModalForm = ({visible, setVisible,getBranch, getCompanyHelper, createBranch,refresh}) => {
      const [roles, setRoles] = useState(null)
      const [company, setCompany] = useState(null)
      const [form] = Form.useForm();

      const createOptions = (label,value) => {
        return {label,value}
      }

      useEffect(async() => {
        const comp = await getCompanyHelper();
        let compOption = [];
        compOption = (
          comp ? comp.map(item => 
            createOptions(item?.name , item?.uuid))
        : null)
        setCompany(compOption)
        
        const res = await getBranch();
        setRoles(res)
      }, [])
      const createRoles = (label,value) => {
        return {label,value}
      }
      const onFinish =async (values) => {
        // console.log('Success:', values);
        const code = await createBranch(values)
        if(code === 201){
          form.resetFields();
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      let option = [];
      option = (
        roles ? roles.map(item => 
          createRoles(item?.name , item?.name  ))
      : null)

    return ( 
          <Modal
          title="Create User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
            
                
                {renderInput('name', 'Branch Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                
                {renderSelect('company', 'Company', [
                    { required: true},
                  ],
                  'Select a option', company, 'none')
                }
                
                {renderSelect('active', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              
              </Form>

        </Modal>
     );
}
 
export default connect(null,{getBranch,createBranch, getCompanyHelper})(ModalForm);
