import history from "../history";
import api from "./apis";

import {
  ERROR_MESSAGE,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "./types";

// ==========================User==============
export const createBranch = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  const object = {
   
    name: data.name,
    company: data.company,
   
    active: data?.active,
    
  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/user-management/branch/create",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: err.response.data[key] }],
        });
      });
      //err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getBranch = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/user-management/branch/all"
    );

    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getBranchHelper = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/user-management/branch/helper/${id}`
    );

    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
export const getBranchById = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/user-management/branch/${id}`
    );

    dispatch({ type: LOADED });
    return response.data;
    // dispatch({ type: GET_USERS, payload: response.data.users });
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const updateBranch = (uuid,data) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  const object = {
    uuid: uuid,
    name: data?.name,
    company: data?.company,
    active: data?.active,
    
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).put(
      "/user-management/branch/update",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User is updated succesfully",
      });

      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: err.response.data[key] }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const deleteBranch = (user, remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const response = await api(localStorage.getItem("token")).delete(
      `/user-management/branch/${user}`
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Company is deleted succesfully",
      });

      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      Object.keys(err.response.data).forEach(function (key, index) {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: `${key} is required`, path: key }],
        });
      });
      // err.response.data?.map(item => {
      // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      // })
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};


export const updateBranchStatus = (uuid, active) => async (dispatch) => {
  dispatch({type: LOADEDING});
  
  const object = {
    uuid,
    active,
  };
  dispatch({type: LOADED});
  try {
    const response = await api(localStorage.getItem("token")).put("/user-management/branch/status",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "Copmany status is updated succesfully"});
    
    return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};
