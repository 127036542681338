import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    LOADED,
    LOADEDING,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    VALIDATION_ERROR
} from './types';



export const createNewRole = (data) => async (dispatch) => {
    dispatch({type: LOADEDING});

    const object =  {
        "name" : data.name,
        "active": true,
        "app_management": data?.app_management ? data?.app_management : false,
        "tv_app_management": data?.tv_app_management ? data?.tv_app_management : false,
        "tv_app_management_admin": data?.tv_app_management_admin ? data?.tv_app_management_admin : false,
        "end_tv_app_user_management": data?.end_tv_app_user_management ? data?.end_tv_app_user_management : false,
        "user_management": data?.user_management ? data?.user_management : false,

    }
    
    try{
    
        const response = await api(localStorage.getItem("token")).post('/user-management/role/create', {...object});
        
        if(response.status === 201){
            dispatch({type: LOADED});
            dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
            history.push('/roles');
        }

    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            
            Object.keys(err.response.data).forEach(function(key, index) {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
            });
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
    }
} 

export const getRoles = () => async (dispatch)=> {
    
    dispatch({type: LOADEDING});
    try{
        const response = await api(localStorage.getItem("token")).get('/user-management/role/all');
        
        dispatch({type: LOADED});
        return response?.data
        // dispatch({type: GET_ROLES, payload: response.data.roles})
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
}

export const getRoleById = (id) => async (dispatch)=> {
    
    dispatch({type: LOADEDING});
    try{
        const response = await api(localStorage.getItem("token")).get(`/user-management/role/${id}`);
        
        dispatch({type: LOADED});
        return response.data
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
}

export const updateRole = (data, role) => async (dispatch) => {
    dispatch({type: LOADEDING});
    const object =  {
        "name" : data.name,
        "active": true,
        "app_management": data?.app_management ? data?.app_management : false,
        "tv_app_management": data?.tv_app_management ? data?.tv_app_management : false,
        "tv_app_management_admin": data?.tv_app_management_admin ? data?.tv_app_management_admin : false,
        "end_tv_app_user_management": data?.end_tv_app_user_management ? data?.end_tv_app_user_management : false,
        "user_management": data?.user_management ? data?.user_management : false,
    }
    try{
        const response = await api(localStorage.getItem("token")).put(`/user-management/role/update/all?id=${role}`, {...object});
        
        if(response.status === 201){
            dispatch({ type: SUCCESS_MESSAGE, payload: "Role is updated Successfully" });
            dispatch({type: LOADED});
            history.push('/roles')
        }
        else{
           
        }

    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            
            Object.keys(err.response.data).forEach(function(key, index) {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
            });
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
    }
}


export const updateRoleAuth = (role, active) => async (dispatch) => {
    dispatch({type: LOADEDING});
    
    const object = {
      active:active,
    };
    dispatch({type: LOADED});
  
    try {
      const response = await api(localStorage.getItem("token")).put(`/user-management/role/status?id=${role}`,{ ...object });
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({type: SUCCESS_MESSAGE,payload: "Role authentication is updated succesfully"});
      
      return response.status
      }
    } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            Object.keys(err.response.data).forEach(function(key, index) {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
            });
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
    }
  };

export const deleteRole = (role, data) => async (dispatch) => {
dispatch({type: LOADEDING});


    try {
        const response = await api(localStorage.getItem("token")).delete(`/user-management/role/delete?id=${role}`);

        if (response.status === 201) {
        
        dispatch({type: LOADED});
        dispatch({type: SUCCESS_MESSAGE,payload: "User is deleted succesfully"});
        
        return response.status
        }
    } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            Object.keys(err.response.data).forEach(function(key, index) {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
            });
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
    }
};


export const getRoleHelpers = () => async (dispatch)=> {
    
    dispatch({type: LOADEDING});
    try{
        const response = await api(localStorage.getItem("token")).get('/user-management/role/helper');
        
        dispatch({type: LOADED});
        return response?.data
        // dispatch({type: GET_ROLES, payload: response.data.roles})
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
}