import React from "react";
//import 'antd/dist/antd.css';
import {
  CheckCircleOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getTvAddsAdmin, updateStatusAdmin } from "../../../actions/adadmin";
import {
  adHelper,
  assignAd,
  convertImage,
  createTvAdd,
  deleteAd,
  getAdById,
  getBranchelpers,
  updateAd,
  updateDoubleStatus,
  updateStatus,
} from "../../../actions/tvadd";
import {
  renderDate,
  renderInput,
  renderSelect,
} from "../../../component/form/form";
import FormSteeper from "../../../component/form/formSteeper";
import history from "../../../history";
import "./style.css";
const Search = Input.Search;
const { Option } = Select;

const dimension = [
  { label: "General", value: "general" },
  { label: "HD", value: "HD" },
  { label: "FHD", value: "FHD" },
  { label: "QHD", value: "QHD" },
  { label: "UHD", value: "UHD" },
];

const TvAddListAdmin = ({ loading, updateStatusAdmin, getTvAddsAdmin }) => {
  const [ID, setID] = useState("");
  const [data, setData] = useState(null);
  const [allApp, setAllApp] = useState([]);
  const [stac, setStac] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [form] = Form.useForm();
  const [aForm] = Form.useForm();
  const [cform] = Form.useForm();
  const [current, setCurrent] = useState(0);

  const [addFiles, setAddFiles] = useState("");
  const [addVideoFiles, setAddVideoFiles] = useState("");
  const [adThumbnail, setAdThumbnail] = useState("");
  const [assignBranch, setAssignBranch] = useState(null);

  const [fileTypeValue, setFileTypeValue] = useState("");
  const [singleFilePosition, setSingleFilePosition] = useState(false);
  const [multiFilePosition, setMultiFilePosition] = useState(false);
  const [selectedTVOption, setSelectedTVOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [doubleSide, setdoubleSide] = useState(null);
  // const [doubleLr, setdoubleLr] = useState(null);
  const [doubleType, setDoubleType] = useState(null);

  // const { token } = theme.useToken();
  const createOption = (value, label) => {
    return { value, label };
  };

  const getData = async () => {
    const res = await getTvAddsAdmin();
    console.log(res);
    if (res?.length > 0) {
      setData(res);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCancel = () => {
    setSelectedOption("");
    setCurrent(0);
    setdoubleSide(null);
    setDoubleType(null);
    setIsCreateModalOpen(false);
  };
  console.log(stac);
  const props = {
    name: "file",
    action:
      "https://api.demo.hidayahsmart.solutions/v3/banner-management/banner/upload",
    headers: {
      "content-type": "multiform/data",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    onRemove: (file) => {
      const res = axios(
        "https://api.demo.hidayahsmart.solutions/v3/banner-management/banner/delete",
        {
          headers: {
            "content-type": "multiform/data",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            file: file?.response?.name,
          },
          method: "delete",
        }
      );
    },
  };
  const handleView = (id) => {
    history.push(`/ad-details?id=${id}`);
  };

  const handleEdit = async (item) => {
    setAddFiles("");
    setAddVideoFiles("");
    setAdThumbnail("");
    setFileTypeValue("");
    setSingleFilePosition(false);
    setMultiFilePosition(false);
    setID(item?.uuid);
    const res = await getAdById(item?.uuid);
    if (res) {
      setFileTypeValue(res[0]?.data?.types);
      setSingleFilePosition(res[0]?.data?.double_status);
      setMultiFilePosition(res[0]?.data?.multi_slide_status);
      setIsEditModalOpen(true);
      form.setFieldsValue({
        embed: res[0]?.data?.name,
        serial: res[0]?.data?.serial.split("_")[1],
        duration: res[0]?.data?.duration,
        status: res[0]?.data?.status,
        default: res[0]?.data?.default,
        double_status: res[0]?.data?.double_status,
        double_type: res[0]?.double_add?.types,
        double_status_c: res[0]?.double_add?.status,
        double_lr: res[0]?.double_add?.double_lr,
        double_side: res[0]?.double_add?.double_side,
        multi_slide_status: res[0]?.data?.multi_slide_status,
        multi_types: res[0]?.multi_add?.multi_types,
        multi_side: res[0]?.multi_add?.multi_side,
        multi_status_c: res[0]?.multi_add?.multi_status_c,
        multi_tb: res[0]?.multi_add?.multi_tb,

        start_at: res[0]?.data?.start_at ? moment(res[0]?.data?.start_at) : "",
        end_at: res[0]?.data?.end_at ? moment(res[0]?.data?.end_at) : "",
      });
    }
  };

  const handleDelete = async (item) => {
    const res = await deleteAd(item?.uuid);
    if (res === 201) {
      getData();
    }
  };

  const handleConvert = async (item) => {
    const res = await convertImage(item?.uuid);
    if (res === 201) {
      getData();
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getImage = async (item) => {
    const res = await getAdById(item?.id);
    return res;
  };
  const handleDoubleStatus = async (id, status) => {
    const res = await updateDoubleStatus(id, status);
    if (res === 201) {
      getData();
    }
  };
  const handleStatus = async (id, status) => {
    const res = await updateStatusAdmin(id, status);
    if (res === 201) {
      getData();
    }
  };
  const createData = (
    id,
    types,
    img,
    status,
    size,
    company,
    start_at,
    end_at
  ) => {
    return { id, types, img, status, size, company, start_at, end_at };
  };

  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "File Type",
      dataIndex: "types",
      key: "types",
      width: "15%",
      ...getColumnSearchProps("types"),
    },
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: "15%",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: "15%",
      ...getColumnSearchProps("company"),
    },
    {
      title: "Start At",
      dataIndex: "start_at",
      key: "start_at",
      width: "15%",
    },
    {
      title: "End At",
      dataIndex: "end_at",
      key: "end_at",
      width: "15%",
    },
  ];

  const rows = [
    data
      ? data?.map((item, key) =>
          createData(
            key + 1,
            item?.types == "banner" ? "Image" : item.types,
            item?.types === "banner" ? (
              <>
                {/* <img className='imgStyle' style={{ width: '63px' }} src={`http://192.168.60.157:5000/static/${item?.uuid}_PIC.png`} /> */}

                <Image
                  width="33%"
                  src={`https://api.demo.hidayahsmart.solutions/static/${item?.uuid}_PIC.png`}
                  style={{ border: "none" }}
                />
                <br />
              </>
            ) : (
              <Image
                width="33%"
                src={`https://api.demo.hidayahsmart.solutions/static/${item?.uuid}_THUM.png`}
                style={{ border: "none" }}
              />
            ),
            item?.status ? (
              <>
                <Tag color="#87d068"> &nbsp;Active &nbsp;</Tag>
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleStatus(item?.uuid, false)}
                >
                  <Button
                    title="Disable"
                    size="small"
                    type="danger"
                    icon={<StopOutlined />}
                  ></Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Tag color="#f50">Inactive</Tag>
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleStatus(item?.uuid, true)}
                >
                  <Button
                    title="Activate"
                    size="small"
                    type="primary"
                    icon={<CheckCircleOutlined />}
                  ></Button>
                </Popconfirm>
              </>
            ),
            parseFloat(item?.size).toFixed(2),
            item?.company,

            // item?.multi_slide_status ? 'Yes' : 'No',
            item?.start_at ? item?.start_at.split("00")[0] : null,
            item?.end_at ? item?.end_at.split("00")[0] : null
            // <img className='img' src ={`https://api.demo.hidayahsmart.solutions/static/book_cover/${item?.data?.image_name_f}`} width = '30px' />,
          )
        )
      : "",
  ];

  const onFinish = async (values) => {
    const res = await createTvAdd(
      values,
      addFiles,
      addVideoFiles,
      adThumbnail,
      doubleType,
      doubleSide
    );
    if (res === 201) {
      setFileTypeValue("");
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
      setCurrent(0);
      setdoubleSide(null);
      setDoubleType(null);
    }
  };

  const onFinishEdit = async (values) => {
    const res = await updateAd(values, ID);
    if (res === 201) {
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishAssign = async (values) => {
    const res = await assignAd(values, ID);
    if (res === 201) {
      getData();
      setIsAssignModal(false);
      aForm.resetFields();
      setID("");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const layout = {
    labelCol: { span: 4, offset: 0 },
    wrapperCol: { span: 20 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const state = [
    { value: "free", label: "Free" },
    { value: "premium", label: "Premium" },
  ];

  const language = [
    { value: "bangla", label: "Bangla" },
    { value: "arabic", label: "Arabic" },
    { value: "urdu", label: "Urdu" },
    { value: "english", label: "English" },
  ];

  const fileType = [
    { value: "banner", label: "Banner" },
    { value: "video", label: "Video" },
    { value: "embedvideo", label: "Embeded Video" },
  ];

  const adPosition = [
    { value: "top", label: "Top" },
    { value: "bottom", label: "Bottom" },
    { value: "left", label: "Left" },
    { value: "right", label: "Right" },
    { value: "topright", label: "Top-right" },
    { value: "bottomright", label: "Bottom-right" },
    { value: "leftbottom", label: "Left-bottom" },
    { value: "rightbottom", label: "Right-bottom" },
  ];

  const adProportion = [
    { value: "1/1", label: "1/1" },
    { value: "2/2", label: "2/2" },
    { value: "3/3", label: "3/3" },
    { value: "4/4", label: "4/4" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ];

  const status = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const statusDes = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const tbposition = [
    { value: "top", label: "Top" },
    { value: "bottom", label: "Bottom" },
  ];

  const doubleLr = [
    { value: "left", label: "Left" },
    { value: "right", label: "Right" },
    { value: "main", label: "Main" },
    { value: "bottom", label: "Bottom" },
  ];

  const handleImageChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    if (
      (e.target.files[0]?.name).split(".")[1] === "jpg" ||
      (e.target.files[0]?.name).split(".")[1] === "jpeg" ||
      (e.target.files[0]?.name).split(".")[1] === "JPG" ||
      (e.target.files[0]?.name).split(".")[1] === "JPEG" ||
      (e.target.files[0]?.name).split(".")[1] === "PNG" ||
      (e.target.files[0]?.name).split(".")[1] === "png"
    ) {
      var type = e.target.files[0].type;
      if (e.target.files && e.target.files[0]) {
        var fsize = e.target.files[0].size / 1024;

        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            450,
            300,
            "JPG",
            100,
            0,
            (uri) => {
              setAddFiles(uri);
            },
            "blob",
            450,
            300
          );
        } catch (err) {}
      }
    } else {
      cform.setFieldsValue({
        imagefiles: "",
      });
      alert("Invalid image format.");
      return;
    }
  };

  const handleFiletype = (e) => {
    cform.setFieldsValue({
      addfiles: "",
      addvideofiles: "",
      thumnil: "",
    });
    setFileTypeValue(e);
    setAddFiles("");
    setAdThumbnail("");
    setAddVideoFiles("");
  };

  const handleFileChange = (event) => {
    // let blob = new Blob([e.target.value], {type: 'ePub'});

    if ((event.target.files[0]?.name).split(".")[1] === "png") {
      if (event.target.name === "addfiles") {
        setAddFiles(event.target.files[0]);
      } else {
        setAdThumbnail(event.target.files[0]);
      }
    } else if ((event.target.files[0]?.name).split(".")[1] === "mp4") {
      if (event.target.name === "addvideofiles") {
        if (event.target.files[0]?.size > 120586240) {
          alert("File size exceeds the maximum limit (100mb)");
          cform.setFieldsValue({
            addvideofiles: "",
          });
          return;
        }
        setAddVideoFiles(event.target.files[0]);
      }
      if (event.target.name === "thumnil") {
        cform.setFieldsValue({
          thumnil: "",
        });
        alert("Invalid file format or file name. Accept only png");
      }
      if (event.target.name === "addfiles") {
        cform.setFieldsValue({
          addfiles: "",
        });
        alert("Invalid file format or file name. Accept only png");
      }
    } else {
      if (event.target.name === "addfiles") {
        cform.setFieldsValue({
          addfiles: "",
        });
        alert("Invalid file format or file name. Accept only png");
      }
      if (event.target.name === "addvideofiles") {
        cform.setFieldsValue({
          addvideofiles: "",
        });
        alert("Invalid file format or file name. Accept only mp4");
      }
      if (event.target.name === "thumnil") {
        cform.setFieldsValue({
          thumnil: "",
        });
        alert("Invalid file format or file name. Accept only png");
      }
      return;
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    cform.setFieldsValue({
      double_lr: null,
    });
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Template",
      content: "First-content",
    },
    {
      title: "Others",
      content: "Second-content",
    },
  ];

  const handleSelector = (checked, name) => {
    console.log(checked);
    setSelectedOption("");
    if (checked) {
      setSelectedOption(name);
    } else setSelectedOption("");
  };

  const handleAssignSelection = (e) => {
    if (!e.includes("all")) setSelectedTVOption(e);
    else setSelectedTVOption(["all"]);
  };

  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ padding: "10px 0px" }}>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>TV Ad Management Admin</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Ad List"
        subTitle="TV Ad Management Admin"
      ></PageHeader>

      <Table
        size="small"
        columns={columns}
        pagination={true}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
      <br />

      <Modal
        title="Create TV Ad"
        open={isCreateModalOpen}
        onCancel={loading ? null : handleCancel}
        width="90%"
        footer={null}
      >
        <FormSteeper data={steps} current={current} />
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          {current === 0 ? (
            <Row gutter={16}>
              <Col
                xs={4}
                className={selectedOption === "single" ? "selected" : false}
              >
                <Image
                  width="100%"
                  src="./ads/1.png"
                  style={{ border: "none" }}
                />
                {/* <Switch size="large" style={{ width: '100%' }} name="single" onClick={(e) => {handleSelector(e, 'single')}}/> */}
                <Button
                  danger={selectedOption === "single" ? true : false}
                  style={{ width: "100%" }}
                  size="middle"
                  onClick={() => {
                    setSelectedOption("single");
                    setdoubleSide(false);
                  }}
                >
                  Single
                </Button>
              </Col>

              {/* <Col xs={4} className={selectedOption === 'bottomLeft' ? 'selected' : false}>
              <Image
                width="100%"
                src="./ads/BottomLeft.png"
              />
              <Button danger = {selectedOption === 'bottomLeft' ? true : false} style={{ width: '100%' }} size='middle' 
              onClick={() => {setSelectedOption('bottomLeft'); setdoubleSide(true); setDoubleType('leftbottom') }}> Bottom Left L shape</Button>
            </Col>
            <Col xs={4} className={selectedOption === 'bottomRight' ? 'selected' : false}>
              <Image
                width="100%"
                src="./ads/BottomRight.png"
              />
              <Button danger = {selectedOption === 'bottomRight' ? true : false} style={{ width: '100%' }} size='middle' 
              onClick={() => {setSelectedOption('bottomRight'); setdoubleSide(true); setDoubleType('rightbottom') }}>  Bottom Right L shape</Button>
            </Col> */}
            </Row>
          ) : // <>
          //   <Form.Item
          //     name="double_status"
          //     label="Overlapping Ad"
          //     rules={[{ required: true, message: 'This field is required!' }]}
          //   >
          //     <Select
          //       showSearch
          //       placeholder="Select one"
          //       size="middle"
          //       allowClear
          //       mode='single'
          //       onChange={(e) => setSingleFilePosition(e)}
          //       disabled={multiFilePosition ? true : null}
          //     >
          //       {statusDes.map(item => (
          //           <Option value={item.value}>{item.label}</Option>
          //       ))}
          //     </Select>
          //   </Form.Item>

          //   {singleFilePosition ?
          //     <>

          //       {renderSelect('double_type', 'Overlapping Type', [
          //           { required: true, message: 'This field is required!' },
          //         ], "", adPosition, 'single')
          //       }

          //       {renderSelect('double_status_c', 'Overlapping Status', [
          //           { required: true, message: 'This field is required!' },
          //         ], "", status, 'single')
          //       }

          //     </>
          //     :
          //   null}

          //   <Form.Item
          //     name="multi_slide_status"
          //     label="Multi Function Ad"
          //     rules={[{ required: true, message: 'This field is required!' }]}
          //   >
          //     <Select
          //       showSearch
          //       placeholder="Select one"
          //       size="middle"
          //       allowClear
          //       mode='single'
          //       onChange={(e) => setMultiFilePosition(e)}
          //       disabled={singleFilePosition ? true : null}
          //     >
          //       {statusDes.map(item => (
          //           <Option value={item.value}>{item.label}</Option>
          //       ))}
          //     </Select>
          //   </Form.Item>

          //   {multiFilePosition ?
          //     <>
          //       {renderSelect('multi_types', 'Multi Function Types', [
          //           { required: true, message: 'This field is required!' },
          //         ], "", adProportion, 'single')
          //       }

          //       {renderSelect('multi_side', 'Multi Function Side', [
          //           { required: true, message: 'This field is required!' },
          //         ], "", status, 'single')
          //       }

          //       {renderSelect('multi_status_c', 'Multi Function Status', [
          //           { required: true, message: 'This field is required!' },
          //         ], "", status, 'single')
          //       }

          //       {renderSelect('multi_tb', 'Multi Function TB', [
          //           { required: true, message: 'This field is required!' },
          //         ], "", tbposition, 'single')
          //       }
          //     </>
          //     :
          //   null}
          // </>
          null}

          {current === 1 ? (
            <Row
              gutter={20}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <Col xs={6} style={{ alignSelf: 'center' }}>
              {selectedOption === 'single' ? 
                <Image
                  width="100%"
                  src="./ads/1.png"
                  style={{border: 'none'}}
                  title='Single'
                />
              :null}
              {selectedOption === 'Left' ? 
                <Image
                  width="100%"
                  src="./ads/left_Ad.png"
                  style={{border: 'none'}}
                />
              :null}
              {selectedOption === 'right' ? 
                <Image
                  width="100%"
                  src="./ads/rAd.png"
                  style={{border: 'none'}}
                />
              :null}
              {selectedOption === 'bottom' ? 
                <Image
                  width="100%"
                  src="./ads/bAd.png"
                  style={{border: 'none'}}
                />
              :null}
              {selectedOption === 'bottomLeft' ? 
                <Image
                  width="100%"
                  src="./ads/BottomLeft.png"
                  style={{border: 'none'}}
                />
              :null}
              {selectedOption === 'bottomRight' ? 
                <Image
                  width="100%"
                  src="./ads/BottomRight.png"
                  style={{border: 'none'}}
                />
              :null}
            </Col> */}

              <Col xs={24}>
                <Form.Item
                  name="types"
                  label="File Type"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select status"
                    size="middle"
                    allowClear
                    mode="single"
                    onChange={(e) => handleFiletype(e)}
                  >
                    {fileType.map((item) => (
                      <Option value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>

                {fileTypeValue === "banner" ? (
                  <>
                    <Form.Item
                      label={"Upload Image"}
                      name="addfiles"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input
                        type="file"
                        name="addfiles"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Form.Item>

                    <Form.Item
                      name="resolution"
                      label="Resolution"
                      rules={[
                        { required: true, message: "This field is required!" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Resolution"
                        size="middle"
                        allowClear
                        mode="single"
                      >
                        {dimension.map((item) => (
                          <Option value={item.value}>{item.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : null}

                {fileTypeValue === "video" ? (
                  <>
                    <Form.Item
                      label={"Upload Thumnail"}
                      name="thumnil"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input
                        type="file"
                        name="thumnil"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Form.Item>

                    <Form.Item
                      label={"Upload Video"}
                      name="addvideofiles"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input
                        type="file"
                        name="addvideofiles"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Form.Item>
                  </>
                ) : null}

                {fileTypeValue === "embedvideo" ? (
                  <>
                    <Form.Item
                      label={"Upload Thumnail"}
                      name="thumnil"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input
                        type="file"
                        name="thumnil"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Form.Item>
                    {renderInput(
                      "embed",
                      "Video Link",
                      [{ required: true, message: "This field is required!" }],
                      "large"
                    )}
                  </>
                ) : null}
                {selectedOption !== "single" ? (
                  <Form.Item
                    name="double_lr"
                    label="File Position"
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="File Position"
                      size="middle"
                      allowClear
                      mode="single"
                    >
                      {selectedOption === "Left" ? (
                        <>
                          <Option value="main">Main</Option>
                          <Option value="left">Left</Option>
                        </>
                      ) : null}
                      {selectedOption === "right" ? (
                        <>
                          <Option value="main">Main</Option>
                          <Option value="right">Right</Option>
                        </>
                      ) : null}
                      {selectedOption === "bottom" ? (
                        <>
                          <Option value="main">Main</Option>
                          <Option value="bottom">Bottom</Option>
                        </>
                      ) : null}
                      {selectedOption === "bottomLeft" ? (
                        <>
                          <Option value="main">Main</Option>
                          <Option value="left">Left</Option>
                        </>
                      ) : null}
                      {selectedOption === "bottomRight" ? (
                        <>
                          <Option value="main">Main</Option>
                          <Option value="right">Right</Option>
                        </>
                      ) : null}
                      {/* {doubleLr.map(item => (
                        <Option value={item.value}>{item.label}</Option>
                    ))} */}
                    </Select>
                  </Form.Item>
                ) : null}

                {renderInput(
                  "serial",
                  "Serial",
                  [{ required: true, message: "This field is required!" }],
                  "large",
                  "number"
                )}
                {fileTypeValue === "banner"
                  ? renderInput(
                      "duration",
                      "Duration in Second",
                      [
                        {
                          required: fileTypeValue === "banner" ? true : false,
                          message: "This field is required!",
                        },
                      ],
                      "large",
                      "number"
                    )
                  : null}
                {/* {renderInput('duration', 'Duration in Second', [
                  { required: fileTypeValue === 'banner' ? true : false, message: 'This field is required!' },
                ], 'large', 'number')
              } */}

                {renderDate(
                  "start_at",
                  "Start Date",
                  [{ required: false, message: "This field is required!" }],
                  "large"
                )}

                {renderDate(
                  "end_at",
                  "End Date",
                  [{ required: false, message: "This field is required!" }],
                  "large"
                )}
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading ? true : false}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </Form>

        <div
          style={{
            marginTop: 24,
          }}
        >
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={selectedOption === "" ? true : false}
            >
              Next
            </Button>
          )}

          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
      </Modal>

      <Modal
        title="Edit TV ad"
        open={isEditModalOpen}
        onOk={() => setIsEditModalOpen(false)}
        onCancel={() => setIsEditModalOpen(false)}
        width={1000}
        footer={null}
        form={form}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {renderInput(
            "serial",
            "Serial",
            [{ required: true, message: "This field is required!" }],
            "large",
            "number"
          )}

          {renderInput(
            "duration",
            "Duration in Second",
            [{ required: true, message: "This field is required!" }],
            "large",
            "number"
          )}

          {renderSelect(
            "status",
            "Status",
            [{ required: true, message: "This field is required!" }],
            "",
            status,
            "single"
          )}

          {renderSelect(
            "default",
            "Default",
            [{ required: true, message: "This field is required!" }],
            "",
            status,
            "single"
          )}
          <Form.Item
            name="double_status"
            label="Overlapping Ad"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              showSearch
              placeholder="Select one"
              size="middle"
              allowClear
              mode="single"
              onChange={(e) => setSingleFilePosition(e)}
              disabled={multiFilePosition ? true : null}
            >
              {statusDes.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>

          {singleFilePosition ? (
            <>
              {renderSelect(
                "double_type",
                "Overlapping Type",
                [{ required: true, message: "This field is required!" }],
                "",
                adPosition,
                "single"
              )}

              {renderSelect(
                "double_status_c",
                "Overlapping Status",
                [{ required: true, message: "This field is required!" }],
                "",
                status,
                "single"
              )}

              {renderSelect(
                "double_lr",
                "Add Position",
                [{ required: true, message: "This field is required!" }],
                "",
                doubleLr,
                "single"
              )}
              {renderSelect(
                "double_side",
                "Both Side",
                [{ required: true, message: "This field is required!" }],
                "",
                status,
                "single"
              )}
            </>
          ) : null}

          {/* <Form.Item
            name="multi_slide_status"
            label="Multi Function Ad"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              showSearch
              placeholder="Select one"
              size="middle"
              allowClear
              mode='single'
              onChange={(e) => setMultiFilePosition(e)}
              disabled={singleFilePosition ? true : null}
            >
              {statusDes.map(item => (
                  <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>  */}

          {multiFilePosition ? (
            <>
              {renderSelect(
                "multi_types",
                "Multi Function Types",
                [{ required: true, message: "This field is required!" }],
                "",
                adProportion,
                "single"
              )}

              {renderSelect(
                "multi_side",
                "Multi Function Side",
                [{ required: true, message: "This field is required!" }],
                "",
                status,
                "single"
              )}

              {renderSelect(
                "multi_status_c",
                "Multi Function Status",
                [{ required: true, message: "This field is required!" }],
                "",
                status,
                "single"
              )}

              {renderSelect(
                "multi_tb",
                "Multi Function TB",
                [{ required: true, message: "This field is required!" }],
                "",
                tbposition,
                "single"
              )}
            </>
          ) : null}

          {renderDate(
            "start_at",
            "Start Date",
            [{ required: false, message: "This field is required!" }],
            "large"
          )}

          {renderDate(
            "end_at",
            "End Date",
            [{ required: false, message: "This field is required!" }],
            "large"
          )}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Assign ad"
        open={isAssignModal}
        onOk={() => setIsAssignModal(false)}
        onCancel={() => setIsAssignModal(false)}
        width={1000}
        footer={null}
      >
        <Form
          {...layout}
          name="Assign ad"
          initialValues={{ remember: true }}
          onFinish={onFinishAssign}
          onFinishFailed={onFinishFailed}
          form={aForm}
        >
          <Form.Item
            name="assign_tv"
            label="Brnach Name"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              showSearch
              placeholder="Select one"
              size="middle"
              allowClear
              mode="multiple"
              onChange={(e) => {
                handleAssignSelection(e);
              }}
            >
              <Option value="all">All</Option>
              {selectedTVOption.includes("all")
                ? null
                : allApp.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
            </Select>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.loader.loading,
  };
};
export default connect(mapStateToProps, {
  assignAd,
  adHelper,
  createTvAdd,
  updateAd,
  deleteAd,
  getAdById,
  convertImage,
  updateDoubleStatus,
  updateStatus,
  getBranchelpers,
  getTvAddsAdmin,
  updateStatusAdmin,
})(TvAddListAdmin);
