import { SettingOutlined } from '@ant-design/icons';
import { Form, Input, Button, Col, Dropdown, Menu, Row,Drawer } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { logOut, updateOwnPassword } from '../actions/auth'

const menu = (logOut,showDrawer) => (
    <Menu style={{ padding: '10px 30px' }}>
      {/* <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          Profile Setting
        </a>
      </Menu.Item> */}
      
      <h3 style={{ textAlign: 'center', margin: '0px'}}> {localStorage.getItem('name')}</h3>
      <Menu.Item onClick = {() => showDrawer()}>
          Password Setting
      </Menu.Item>
      <Menu.Item>
        <Button onClick = { () => logOut()}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
const Topbar = ({updateOwnPassword,logOut}) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onFinish = async (values) => {
    const code = await updateOwnPassword(values)
    if(code === 201 ) {
      setVisible(false);
    } 
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
    return ( 
        <Header className="site-layout-background" style={{backgroundColor: '#ffffff', color: '#000000'}} >
          <Row>
              <Col sm={12} style={{position: 'absolute', right: '10px'}}>
                
              <Dropdown overlay={menu(logOut,showDrawer)} placement="bottomRight">
                  <Button type="primary" shape="circle" icon={<SettingOutlined />} />
              </Dropdown>
              </Col>
          </Row>
          
          <Drawer
            title="Update Password"
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            width="500"
          >
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >

              <Form.Item
                label="Old Password"
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>

            </Form>

          </Drawer>
        </Header>
     );
}
 
export default connect(null,{logOut,updateOwnPassword})(Topbar);